/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable no-nested-ternary */
import React, {
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import { useAgentReducer } from 'use-agent-reducer';
import { message } from 'antd';
import classNames from 'classnames';
import { compact, omit } from 'lodash';
import { Empty } from '@/components/layouts/empty';
import { Button } from '@/components/widgets/button';
import { Icon } from '@/components/widgets/icon';
import { TextArea } from '@/components/widgets/input';
import { Dialog, Modal, useDialogMethods } from '@/components/widgets/modal';
import { DialogDeployProps } from '@/components/widgets/modal/dialogs';
import type { EsItem, SkuWebDto, SpuItemDto } from '@/types/esItem';
import { Option, Select } from '@/components/widgets/select';
import useDebounceFn from '@/hooks/useDebounceFn';
import { warnError } from '@/components/infos';
import chatpeerRequest from '@/requests/chatpeer';
import Loading from '@/components/widgets/loading/Loading';
import { VirtualDiv2 } from '@/components/layouts/virtualList';
import { Dropdown } from '@/components/widgets/dropdown';
import { Menu, MenuItem } from '@/components/widgets/menu';
import { ItemHelpTooltip } from '../itemHelpTooltip';
import { SkuItem } from '../skuItem';
import { ItemSelectorModel } from './model';
import { GoodsSelectModalProps } from './type';
import css from './style.less';
import { ApproveStatus } from '@/types/approveStatus';
import { SpuItem } from '../spuItem';
import { copy } from '@/utils/dxUtils';
import { Radio, RadioGroup } from '@/components/widgets/radio';
import { ItemListMode } from '../../type';
import { usePlatform } from '@/hooks/usePlatform';
import { getItem } from '@/utils/storage';
import { mergeIidSkuIdsJD } from '../../service';
import { BatchSkuSelectDto } from '@/requests/chatpeer/type';

const AUTO_SIZE = { minRows: 1, maxRows: 6 };
const SCROLL_TRIGGER_DISTANCE = 5;

type TipProps = {
  successNum: number;
  repeatIds: string[];
  repeatItemIds: string[];
  notFoundIds: string[];
  successList: EsItem[] | SkuWebDto[];
};

const PatchSelectDialog = memo(
  (props: DialogDeployProps<{ ids: string[]; limit: number }>) => {
    const {
      closeDialog,
      params: { ids = [], limit }
    } = props;

    const [value, setValue] = useState('');
    const handleOk = async () => {
      if (!value.trim()) {
        message.warning(`请输入商品ID`);
        return;
      }
      const patchList = compact(value.trim().split('\n')) || [];
      if (ids.length + patchList.length > limit) {
        message.warning(`至多选择${limit}个商品（已选择${ids.length}个）`);
        closeDialog();
        return;
      }
      try {
        const {
          itemList,
          repeatTaobaoIids,
          addedTaobaoIids,
          notFoundTaobaoIids
        } = await chatpeerRequest.batchFetchList({
          searchTaobaoIids: value,
          selectedTaobaoIids: ids
        });
        const { length } = [...addedTaobaoIids, ...ids];
        if (length > 5000) {
          if (ids.length) {
            message.warning(`最多选择5000个商品（已选择${ids.length}个`);
          } else {
            message.warning('最多选择5000个商品');
          }
          return;
        }
        closeDialog({
          successNum: itemList.length,
          repeatIds: [...new Set(repeatTaobaoIids)],
          repeatItemIds: [...new Set(addedTaobaoIids)],
          notFoundIds: [...new Set(notFoundTaobaoIids)],
          successList: itemList
        });
      } catch (e) {
        warnError(e);
      }
    };

    return (
      <Dialog
        title="批量选择商品"
        onCancel={() => closeDialog(null)}
        wrapClassName={css.modal}
        width={540}
        onOk={handleOk}
      >
        <div>
          <div>请将商品ID换行输入到输入框中</div>
          <div>
            <TextArea
              autoSize={{ minRows: 12, maxRows: 12 }}
              placeholder={'示例：\n655173962383\n655173962384'}
              value={value}
              onChange={setValue}
            />
          </div>
        </div>
      </Dialog>
    );
  }
);

const PatchSkuSelectDialog = memo(
  (props: DialogDeployProps<{ ids: string[]; limit: number }>) => {
    const {
      closeDialog,
      params: { ids = [], limit }
    } = props;

    const [value, setValue] = useState('');
    const handleOk = async () => {
      if (!value.trim()) {
        message.warning(`请输入商品SKU`);
        return;
      }
      const patchList = compact(value.trim().split('\n')) || [];
      if (ids.length + patchList.length > limit) {
        message.warning(`至多选择${limit}个商品（已选择${ids.length}个）`);
        closeDialog();
        return;
      }
      try {
        const {
          skuList,
          addedTaobaoSkuIds,
          repeatTaobaoSkuIds,
          notFoundTaobaoSkuIds
        } = await chatpeerRequest.batchFetchSkuList({
          searchTaobaoSkuIds: value,
          selectedTaobaoSkuIds: ids
        });
        const { length } = [...addedTaobaoSkuIds, ...ids];
        if (length > 5000) {
          if (ids.length) {
            message.warning(`最多选择5000个商品（已选择${ids.length}个`);
          } else {
            message.warning('最多选择5000个商品');
          }
          return;
        }
        closeDialog({
          successNum: skuList.length,
          repeatIds: [...new Set(repeatTaobaoSkuIds)],
          repeatItemIds: [...new Set(addedTaobaoSkuIds)],
          notFoundIds: [...new Set(notFoundTaobaoSkuIds)],
          successList: skuList
        });
      } catch (e) {
        warnError(e);
      }
    };

    return (
      <Dialog
        title="批量选择商品"
        onCancel={() => closeDialog(null)}
        wrapClassName={css.modal}
        width={540}
        onOk={handleOk}
      >
        <div>
          <div>请将商品SKU换行输入到输入框中</div>
          <div>
            <TextArea
              autoSize={{ minRows: 12, maxRows: 12 }}
              placeholder={'示例：\n655173962383\n655173962384'}
              value={value}
              onChange={setValue}
            />
          </div>
        </div>
      </Dialog>
    );
  }
);

const tipDialog = memo((props: DialogDeployProps<TipProps>) => {
  const {
    closeDialog,
    params: { successNum, repeatIds, repeatItemIds, notFoundIds }
  } = props;

  return (
    <Dialog
      title="选择结果"
      onCancel={closeDialog}
      wrapClassName={css.modal}
      width={540}
      onOk={closeDialog}
      footer={
        <Button type="primary" onClick={closeDialog}>
          确定
        </Button>
      }
    >
      <div>
        <div className={css.tipTitle}>
          选择成功<span>{successNum}</span>个商品
        </div>
        {repeatIds.length ? (
          <div>
            <div className={css.grayColor}>商品ID重复：</div>
            <div>{repeatIds.join('、')}</div>
          </div>
        ) : null}
        {repeatItemIds.length ? (
          <div>
            <div className={css.grayColor}>重复选择商品：</div>
            <div>{repeatItemIds.join('、')}</div>
          </div>
        ) : null}
        {notFoundIds.length ? (
          <div>
            <div className={css.grayColor}>未找到商品：</div>
            <div>{notFoundIds.join('、')}</div>
          </div>
        ) : null}
      </div>
    </Dialog>
  );
});

const ItemClearDropdown = memo(
  (props: { clearItem: () => void; clearItemInStock: () => void }) => {
    const { clearItem, clearItemInStock } = props;

    return (
      <Dropdown
        overlay={
          <Menu>
            <MenuItem key="clearItem" onClick={clearItem}>
              清空全部商品
            </MenuItem>
            <MenuItem key="clearItemInstock" onClick={clearItemInStock}>
              清空仓库中商品
            </MenuItem>
          </Menu>
        }
        placement="bottomRight"
      >
        <div className={css.clear}>
          <span>清空商品</span>
          <Icon type="xiala" style={{ marginLeft: '4px' }} />
        </div>
      </Dropdown>
    );
  }
);

export default memo((props: GoodsSelectModalProps) => {
  const {
    limit,
    modalAction,
    showSku,
    selectLittleItem,
    selectRecommendItem,
    items,
    skus,
    taobaoIidskuids,
    onChange
  } = props;
  const [openDialog] = useDialogMethods();
  const [loading, setLoading] = useState(true);
  const { platform, isJD } = usePlatform();

  const isSearching = useRef(false);

  const {
    state,
    interaction,
    setKeyword,
    handleApproveStatusChange,
    setEditing,
    initial,
    fetchList,
    fetchSkuList,
    addItems,
    removeItem,
    addSku,
    removeSku,
    clearSku,
    clearItem,
    clearItemInStock,
    getSelectItems,
    getSelectItemsJD
  } = useAgentReducer(new ItemSelectorModel({ items, skus }));

  const {
    selectList = [],
    selectSkuList = [],
    searchValue,
    searchApproveStatus,
    isEditing,
    dataSource,
    page,
    total,
    skuDataSource,
    skuPage,
    skuTotal
  } = state;
  const scrollView = useRef<HTMLDivElement | null>(null);
  const skuScrollView = useRef<HTMLDivElement | null>(null);

  const [itemListMode, setItemListMode] = useState<ItemListMode>('SPU');

  const placeholder = isJD
    ? itemListMode === 'SPU'
      ? '商品标题/商品货号/商品编码'
      : '商品标题/商家SKU/商品SKU编号'
    : '支持商品ID/商品标题/商家编码';

  const taobaoIidSet = useMemo(() => {
    return new Set(selectList.map(v => v.taobaoIid));
  }, [selectList]);

  const taobaoSkuIdSet = useMemo(() => {
    return new Set(selectSkuList.map(v => v.taobaoSkuId));
  }, [selectSkuList]);

  const selectSkuLength = isJD
    ? mergeIidSkuIdsJD(selectSkuList, selectList).filter(i => i.taobaoSkuId)
        .length
    : selectSkuList?.length;

  useEffect(() => {
    interaction.implement({
      // eslint-disable-next-line @typescript-eslint/no-shadow
      changeItemsAndSkus(items) {
        onChange(items);
      }
    });
  }, []);

  // 滚动左边商品列表
  const scrollTo = (position: number) => {
    const element = scrollView.current;
    const skuElement = skuScrollView.current;

    if (element) {
      element.scrollTop = position;
    }

    if (skuElement) {
      skuElement.scrollTop = position;
    }
  };

  const patchSelect = async () => {
    if (selectList.length >= limit) {
      message.warning(`至多选择${limit}个商品（已选择${selectList.length}个）`);
      return;
    }

    const res = (await openDialog(PatchSelectDialog, {
      ids: selectList.map(v => v.taobaoIid),
      limit
    })) as TipProps;
    if (!res) {
      return;
    }

    const { successList, ...rest } = res;
    addItems(successList as SpuItemDto[]);
    openDialog(tipDialog, rest);
  };

  const patchSkuSelect = async () => {
    if (selectList.length >= limit) {
      message.warning(`至多选择${limit}个商品（已选择${selectList.length}个）`);
      return;
    }

    const res = (await openDialog(PatchSkuSelectDialog, {
      ids: selectSkuList.map(v => v.taobaoSkuId),
      limit
    })) as TipProps;
    if (!res) {
      return;
    }

    const { successList, ...rest } = res;
    const successItems = successList.reduce((pre, v) => {
      const { taobaoIid } = v;
      const spuItem = dataSource.find(i => String(i.taobaoIid) === String(taobaoIid));

      if (spuItem && !taobaoIidSet.has(v.taobaoIid)) {
        return [...pre, spuItem];
      }
      return pre;
    }, [] as SpuItemDto[]);

    addItems(successItems);
    addSku(successList as SkuWebDto[]);
    openDialog(tipDialog, rest);
  };

  const onSearch = useDebounceFn(async () => {
    isSearching.current = true;
    const values = new Set(searchValue.split('\n').filter(v => v));
    if (values.size > 50) {
      const isUseBatchItem = await Modal.confirm({
        title: '温馨提示',
        content:
          '只支持搜索50个以内的商品，需要批量选择更多商品时，请使用 “批量选择商品”',
        okText: '批量选择商品'
      });
      if (isUseBatchItem) {
        if (isJD) {
          patchSkuSelect();
        } else {
          patchSelect();
        }
      }
      return;
    }
    setLoading(true);
    scrollTo(0);
    try {
      await Promise.all([fetchList({ page: 1 }), fetchSkuList({ page: 1 })]);
    } catch (e) {
      warnError(e);
    } finally {
      setLoading(false);
    }
  });

  useEffect(() => {
    (async () => {
      try {
        await initial({ modalAction, selectRecommendItem });
        if (!items.length && taobaoIidskuids?.length) {
          if (isJD) {
            getSelectItemsJD(taobaoIidskuids);
          } else {
            getSelectItems(taobaoIidskuids);
          }
        }
      } catch (e) {
        warnError(e);
      } finally {
        setLoading(false);
      }
    })();
  }, [taobaoIidskuids, isJD]);

  const handleSpuSelect = useCallback(
    (item: SpuItemDto) => {
      if (taobaoIidSet.has(item.taobaoIid)) {
        removeItem(item.taobaoIid);
        clearSku(item.taobaoIid);
        return;
      }

      if (
        modalAction === 'addRecommendItem' &&
        [...selectList, item].length + selectRecommendItem.length > 6
      ) {
        message.warning(`至多选择${6 - selectRecommendItem.length}个商品`);
        return;
      }
      if (selectList.length >= limit) {
        message.warning(
          `至多选择${limit}个商品${
            !selectLittleItem ? `（已选择${selectList.length}个）` : ''
          }`
        );
        return;
      }

      if (isJD) {
        addSku([...item.skuList]);
      }

      addItems([item]);
    },
    [selectList, isJD]
  );

  const handleSkuSelect = (item: SkuWebDto) => {
    const { taobaoIid } = item;
    const spuItem = dataSource.find(i => i.taobaoIid === taobaoIid);

    if (spuItem && !taobaoIidSet.has(item.taobaoIid)) {
      addItems([spuItem]);
    }

    if (taobaoSkuIdSet.has(item.taobaoSkuId)) {
      removeSku(item.taobaoSkuId);

      taobaoSkuIdSet.delete(item.taobaoSkuId);
      const shouldRemoveSpu = spuItem?.skuList.some(i =>
        taobaoSkuIdSet.has(i.taobaoSkuId)
      );
      if (!shouldRemoveSpu) {
        removeItem(taobaoIid);
      }

      return;
    }
    addSku([item]);
  };

  // 滚动到底部请求数据
  const onScroll = useDebounceFn(async () => {
    if (isSearching.current) {
      isSearching.current = false;
      return;
    }
    const element = scrollView.current;
    if (!element) {
      return;
    }
    const position = element.scrollTop + element.clientHeight;
    if (element.scrollHeight - position <= SCROLL_TRIGGER_DISTANCE) {
      if (itemListMode === 'SPU') {
        await fetchList({ page: page + 1 });
      } else {
        await fetchSkuList({ page: skuPage + 1 });
      }
    }
  }, 300);

  const addAllItem = useDebounceFn(async () => {
    if (selectList.length > limit) {
      message.warning(`至多选择${limit}个商品（已选择${selectList.length}个）`);
      return;
    }
    const res = await fetchList({ page: 1, size: total });
    const data = res.dataSource;
    const needAddItems = data.filter(item => !taobaoIidSet.has(item.taobaoIid));
    if (
      modalAction === 'addRecommendItem' &&
      needAddItems.length + selectList.length + selectRecommendItem.length > 6
    ) {
      message.warning(`至多选择${6 - selectRecommendItem.length}个商品`);
      return;
    }
    if (needAddItems.length + selectList.length > limit) {
      message.warning(`至多选择${limit}个商品（已选择${selectList.length}个）`);
    } else {
      addItems(needAddItems);
    }
  }, 500);

  const addAllItemJD = useDebounceFn(async () => {
    if (selectList.length > limit) {
      message.warning(`至多选择${limit}个商品（已选择${selectList.length}个）`);
      return;
    }
    const { dataSource } = await fetchSkuList({ page: 1, size: skuTotal });
    const needAddItems = dataSource.filter(
      item => !taobaoIidSet.has(item.taobaoIid)
    );
    const needAddSkus = needAddItems
      .flatMap(i => i.skuList)
      .filter(skus => !taobaoSkuIdSet.has(skus.taobaoSkuId));

    if (
      modalAction === 'addRecommendItem' &&
      needAddItems.length + selectList.length + selectRecommendItem.length > 6
    ) {
      message.warning(`至多选择${6 - selectRecommendItem.length}个商品`);
      return;
    }
    if (needAddItems.length + selectList.length > limit) {
      message.warning(`至多选择${limit}个商品（已选择${selectList.length}个）`);
    } else {
      addItems(needAddItems);
      addSku(needAddSkus);
    }
  }, 500);

  const changeApproveStatus = (v: ApproveStatus) => {
    handleApproveStatusChange(v);
    scrollTo(0);
  };

  const copySelectSpuIds = () => {
    if (isJD) {
      copy(selectSkuList.map(i => i.taobaoSkuId).join('\n'));
      return;
    }
    copy(selectList.map(i => i.taobaoIid).join(','));
  };

  return (
    <>
      <div className={css.search}>
        <div className={css.selectContainer}>
          <div className={css.searchLeft}>
            {isJD ? (
              <RadioGroup
                style={{ marginRight: '12px' }}
                value={itemListMode}
                onChange={e => setItemListMode(e.target.value)}
              >
                <Radio.Button value="SPU">商品模式</Radio.Button>
                <Radio.Button value="SKU">SKU模式</Radio.Button>
              </RadioGroup>
            ) : null}

            <Select
              value={searchApproveStatus}
              className={css.select}
              style={{ width: 100 }}
              onChange={changeApproveStatus}
            >
              <Option value="ALL">全部</Option>
              <Option value="ONSALE">出售中</Option>
              <Option value="INSTOCK">仓库中</Option>
            </Select>
          </div>
          <div className={css.searchRight}>
            {isEditing ? (
              <div className={css.textAreaBox}>
                <div className={css.absoluteBox}>
                  <TextArea
                    className={css.textArea}
                    placeholder={placeholder}
                    onBlur={() => setEditing(false)}
                    autoSize={AUTO_SIZE}
                    onChange={setKeyword}
                    autoFocus
                    value={searchValue}
                  />
                </div>
              </div>
            ) : (
              <div
                className={classNames(
                  css.keywordView,
                  !searchValue && css.keywordPlaceholder
                )}
                onClick={() => setEditing(true)}
              >
                {searchValue || placeholder}
              </div>
            )}
            <ItemHelpTooltip mode={itemListMode} />
            <Button type="primary" onClick={onSearch} className={css.searchBtn}>
              查询
            </Button>
            {selectLittleItem ? null : (
              <Button
                type="primary"
                onClick={isJD ? patchSkuSelect : patchSelect}
                className={css.patch}
              >
                批量选择商品
              </Button>
            )}
          </div>
        </div>
      </div>

      <div className={`${css.content} ${css.contentEdit}`}>
        <div className={css.card} style={{ marginRight: 12 }}>
          <div className={css.cardHeader}>
            <div>
              {modalAction === 'addRecommendItem'
                ? `可选商品数量${Math.max(
                    total - selectRecommendItem.length,
                    0
                  )}件（不包含主商品和已关联商品）`
                : `查询结果${itemListMode === 'SPU' ? total : skuTotal}件`}
            </div>
            {(itemListMode === 'SPU' ? total : skuTotal) &&
            !loading &&
            limit !== 1 &&
            !selectLittleItem ? (
              <div
                className={css.clear}
                onClick={() => (isJD ? addAllItemJD() : addAllItem())}
              >
                全部添加
              </div>
            ) : null}
          </div>
          {/* SPU */}
          <div
            className={css.scroll}
            onScroll={onScroll}
            ref={scrollView}
            style={{ display: itemListMode === 'SPU' ? 'block' : 'none' }}
          >
            <Loading loading={loading}>
              {dataSource.length ? (
                dataSource.map(item => (
                  <SpuItem
                    showSku={showSku}
                    item={item}
                    type="wait"
                    selected={taobaoIidSet.has(item.taobaoIid)}
                    key={item.taobaoIid}
                    onSpuSelect={handleSpuSelect}
                    taobaoSkuIdSet={taobaoSkuIdSet}
                    onSkuSelect={handleSkuSelect}
                  />
                ))
              ) : (
                <div className={css.emptyCtn}>
                  <Empty text="暂无更多商品" />
                </div>
              )}
            </Loading>
          </div>

          {/* SKU */}
          <div
            className={css.scroll}
            onScroll={onScroll}
            ref={skuScrollView}
            style={{ display: itemListMode === 'SKU' ? 'block' : 'none' }}
          >
            <Loading loading={loading}>
              {skuDataSource.length ? (
                skuDataSource.map(item => (
                  <SkuItem
                    item={item}
                    type="wait"
                    selected={taobaoSkuIdSet.has(item.taobaoSkuId)}
                    key={item.taobaoSkuId}
                    onSelect={handleSkuSelect}
                  />
                ))
              ) : (
                <div className={css.emptyCtn}>
                  <Empty text="暂无更多SKU" />
                </div>
              )}
            </Loading>
          </div>
        </div>

        <div className={css.card}>
          <div className={css.cardHeader}>
            <div>
              {modalAction === 'addRecommendItem' ? '新增关联商品' : '已选商品'}
              {selectList?.length}件
              {showSku ? `，${selectSkuLength}个SKU` : null}
              <Button type="link" onClick={copySelectSpuIds}>
                {`复制已选${isJD ? 'SKU编号' : '商品ID'}`}
              </Button>
            </div>
            {selectList?.length && limit > 1 ? (
              <ItemClearDropdown
                clearItem={clearItem}
                clearItemInStock={clearItemInStock}
              />
            ) : null}
          </div>

          <VirtualDiv2
            className={css.scroll}
            style={{ display: itemListMode === 'SPU' ? 'block' : 'none' }}
            dataSource={selectList || []}
            itemHeight={76}
            renderItem={(item: SpuItemDto) => (
              <SpuItem
                showSku={showSku}
                item={item}
                type="selected"
                selected
                key={item.taobaoIid}
                onSpuSelect={handleSpuSelect}
                taobaoSkuIdSet={taobaoSkuIdSet}
                onSkuSelect={handleSkuSelect}
              />
            )}
          />
          {/* SKU */}
          <VirtualDiv2
            className={css.scroll}
            style={{ display: itemListMode === 'SKU' ? 'block' : 'none' }}
            dataSource={selectSkuList || []}
            itemHeight={76}
            renderItem={(item: SkuWebDto) => (
              <SkuItem
                item={item}
                type="selected"
                selected={taobaoSkuIdSet.has(item.taobaoSkuId)}
                key={item.taobaoSkuId}
                onSelect={handleSkuSelect}
              />
            )}
          />
        </div>
      </div>
    </>
  );
});
